import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
import { ContactInfoWrapper, InfoBoxWrap, InfoBox } from './contact-info-area.style'

const ContactInfoArea = ({ headingStyle, textStyle, phoneStyle }) => {
	const contactInfo = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    contact {
                        addressInfos {
                            id
                            state
                            address
                            email
                            phone
                        }
                    }
                }
            }
        }      
    `);
	const { addressInfos } = contactInfo.site.siteMetadata.contact;
	return (
		<ContactInfoWrapper>
			<Container>
				<Row>
					{addressInfos && addressInfos.map(info => (
						<Col lg={4} key={info.id}>
							<InfoBoxWrap>
								{info.state && <Heading {...headingStyle}>{info.state}</Heading>}
								<InfoBox>
									{info.address && <Text {...textStyle}>{info.address}</Text>}
									<Text {...textStyle}><a href="javascript:location='mailto:\u0064\u0069\u0072\u0065\u0063\u0074\u006f\u0072\u0040\u0074\u0075\u006c\u0069\u0070\u002e\u0061\u0063\u0061\u0064\u0065\u006d\u0079';void 0">Email Enquiry</a></Text>
									<Text {...textStyle}><a href="javascript:location='tel:\u002b\u0036\u0031\u0028\u0033\u0029\u0020\u0039\u0032\u0035\u0031\u0020\u0037\u0034\u0033\u0034';void 0">Call TULIP Lab</a></Text>
								</InfoBox>
							</InfoBoxWrap>
						</Col>
					))}
				</Row>
			</Container>
		</ContactInfoWrapper>
	)
}

ContactInfoArea.propTypes = {
	headingStyle: PropTypes.object,
	textStyle: PropTypes.object,
	phoneStyle: PropTypes.object,
}

ContactInfoArea.defaultProps = {
	headingStyle: {
		as: "h5",
		mb: "20px"
	},
	textStyle: {
		mb: "7px"
	},
	phoneStyle: {
		fontweight: 800,
		color: 'headingColor'
	}
}

export default ContactInfoArea;