import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import GoogleMap from "@ui/google-map";
import { SectionWrap } from "./style";

const TulipMapSection = () => {
	return (
		<SectionWrap>
			<Container>
				<Row>
					<Col lg={12}>
						<GoogleMap />
					</Col>
				</Row>
			</Container>
		</SectionWrap>
	)
}

export default TulipMapSection
